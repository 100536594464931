import React from 'react';
import Button from '../pattern/Button';
import { error_page_icon } from '../../assets';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getHomeRoutes } from '../../utils/helperFunction';

const GenericErrorPage = (props) => {
  const navigate = useNavigate();

  const {
    onFirstButtonClick,
    onSecondButtonClick,
    firstButtonText,
    secondButtonText,
    hideFirstButton,
    hideSecondButton,
    titleText,
    messageText,
    contentHeight = '50vh',
  } = props;

  const { t } = useTranslation();
  const handleFirstButtonClick = () => {
    if (typeof onFirstButtonClick === 'function') {
      onFirstButtonClick();
    } else {
      navigate(-1);
    }
  };

  const handleSecondButtonClick = () => {
    if (typeof onSecondButtonClick === 'function') {
      onSecondButtonClick();
    } else {
      navigate(getHomeRoutes());
    }
  };

  return (
    <div className='error-page-container' style={{ height: contentHeight }}>
      <div>
        <img className='' src={error_page_icon} />
      </div>
      <div className='d-flex flex-column align-items-center'>
        <h2>{titleText ? titleText : t('error.sorry')}</h2>
        <span>{messageText ? messageText : t('error.genericPageText')}</span>
      </div>
      <div className='d-flex gap-2'>
        {!hideFirstButton && (
          <Button className={'default-btn'} onClick={handleFirstButtonClick}>
            {firstButtonText ? firstButtonText : t('back')}
          </Button>
        )}
        {!hideSecondButton && (
          <Button className={'primary-btn'} onClick={handleSecondButtonClick}>
            {secondButtonText ? secondButtonText : t('backToFirstPage')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GenericErrorPage;
