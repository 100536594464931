import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout_icon,
  dropdown_icon_white,
  profile_photo,
  profile_icon,
  role_change_icon,
} from '../../assets';
import { en2BnConversion } from '../../utils/helperFunction';
import { generalReducer } from '../../redux/generalSlice';
import { logout } from '../../services/logoutService';
import { authInfo, successfulLogin } from '../../pages/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../constants/appRoutes';
import AppModal from '../pattern/AppModal';
import { colorVariant, localizationNS } from '../../constants/appConstants';
import { Form } from 'react-bootstrap';
import { switchUserDuty } from '../../pages/auth/authApi';

const NavProfile = ({
  className,
  toggleClass,
  nameDesignationClass,
  profileNameClass,
  designationNameClass,
  workStationNameClass,
  dropdownItemClassName,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tCommon = useTranslation(localizationNS.common).t;

  const {
    user: {
      imagePath,
      fullNameBangla,
      fullNameEnglish,
      designationBangla,
      designationEnglish,
      activeJurisdiction: { workstationNameEnglish, workstationNameBangla, dutyId },
      jurisdictions,
    },
  } = useSelector(authInfo);

  const { appLanguage } = useSelector(generalReducer);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedDutyId, setSelectedDutyId] = useState(dutyId);

  const handleUserDuty = (dutyId) => {
    setSelectedDutyId(dutyId);
  };

  const updateUserDuty = () => {
    switchUserDuty(selectedDutyId)
      .then((response) => {
        const { expiresAt, token, refreshBefore, user } = response.data;
        dispatch(successfulLogin({ expiresAt, token, refreshBefore, user }));
        setShowModal(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Dropdown className={`nav-profile ${className}`} align={'end'} {...rest}>
        <Dropdown.Toggle className={`nav-profile-dropdown ${toggleClass}`}>
          <div className='d-flex align-items-center gap-3'>
            <div>
              <img
                src={imagePath || profile_photo}
                className='nav-profile-img'
                alt='Profile picture'
              />
            </div>
            <div className={`nav-name-designation ${nameDesignationClass}`}>
              <div className='d-flex align-items-center'>
                <div className={`profile-name-text ${profileNameClass}`}>
                  {en2BnConversion(fullNameBangla, fullNameEnglish, appLanguage)}
                </div>
                , &nbsp;
                <div className={`profile-designation-text ${designationNameClass}`}>
                  {en2BnConversion(designationBangla, designationEnglish, appLanguage)}
                </div>
              </div>
              <div className={`profile-workstation-text ${workStationNameClass}`}>
                {en2BnConversion(workstationNameBangla, workstationNameEnglish, appLanguage)}
              </div>
            </div>
            <div>
              <img src={dropdown_icon_white} className='img-fluid' alt='dropdown icon' />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              navigate(appRoutes.userProfile);
            }}
            className={dropdownItemClassName}
          >
            <img src={profile_icon} alt='profile icon' />
            <span>{t('topbar.profile')}</span>
          </Dropdown.Item>

          {jurisdictions.length > 1 && (
            <Dropdown.Item
              onClick={() => {
                setShowModal(true);
              }}
              className={dropdownItemClassName}
            >
              <img src={role_change_icon} alt='profile icon' />
              <span>{t('topbar.roleChange')}</span>
            </Dropdown.Item>
          )}

          <Dropdown.Item
            onClick={() => {
              dispatch(logout());
            }}
            className={dropdownItemClassName}
          >
            <img src={logout_icon} alt='logout icon' />
            <span className='text-danger'>{t('topbar.logout')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <AppModal
        show={showModal}
        title={t('topbar.roleChangeTitle')}
        handleCancel={() => setShowModal(false)}
        handleAccept={updateUserDuty}
        centered={true}
        backdrop='static'
        cancelText={tCommon('cancel')}
        acceptText={tCommon('ok')}
        cancelBtnVariant={colorVariant.secondary}
        acceptBtnVariant={colorVariant.primary}
        size='lg'
      >
        <div>
          <p>{t('topbar.roleText')}</p>

          <Form>
            {jurisdictions.map((jurisdiction) => (
              <Form.Label
                key={jurisdiction.dutyId}
                className='d-flex justify-content-between align-items-center p-3 border-top duty-change pointer'
                htmlFor={jurisdiction.dutyId}
              >
                <span>
                  <span className='fw-bold'>
                    {en2BnConversion(
                      jurisdiction.workstationNameBangla,
                      jurisdiction.workstationNameEnglish,
                      appLanguage,
                    )}
                  </span>
                  {jurisdiction.roleNameBangla && (
                    <span className='opacity-50'>
                      ,{' '}
                      {en2BnConversion(
                        jurisdiction.roleNameBangla,
                        jurisdiction.roleNameEnglish,
                        appLanguage,
                      )}
                    </span>
                  )}

                  {jurisdiction.isPrimary && (
                    <span className='rounded px-2 py-1 category-A ms-3 text-nowrap'>
                      {t('topbar.primaryDuty')}
                    </span>
                  )}
                </span>

                <Form.Check
                  id={jurisdiction.dutyId}
                  type='radio'
                  value={jurisdiction.dutyId}
                  checked={selectedDutyId === jurisdiction.dutyId}
                  onChange={() => handleUserDuty(jurisdiction.dutyId)}
                  className='duty-change-radio'
                />
              </Form.Label>
            ))}
          </Form>
        </div>
      </AppModal>
    </>
  );
};

export default NavProfile;
