import {
  LOCATION_TYPE,
  REPORT_TYPE,
  UPLOAD_PATH,
  appLanguages,
  colorVariant,
  dateFormat,
} from '../constants/appConstants';
import { appRoutes } from '../constants/appRoutes';
import { STATUS } from '../constants/fetchStatus';
import { store } from '../redux/store';

export const convertToDropdownItems = (items = [], lng = appLanguages.English) => {
  let results = [];

  items.forEach((e) => {
    results.push({
      value: e.id,
      label: lng === appLanguages.Bengali ? e.nameBangla : e.nameEnglish,
    });
  });

  return results;
};

export const toDropdownItems = (
  items = [],
  lng = appLanguages.English,
  value,
  labelBn,
  labelEn,
) => {
  let results = [];

  items.forEach((e) => {
    results.push({
      value: e[value],
      label: lng === appLanguages.Bengali ? e[labelBn] : e[labelEn],
    });
  });

  return results;
};

export const toSessionDropdownItems = (items = [], lng = appLanguages.English, value) => {
  let results = [];

  items.forEach((e) => {
    results.push({
      value: e[value],
      label:
        lng === appLanguages.Bengali
          ? digitConversion(e[value], lng)
          : digitConversion(e[value], lng),
    });
  });
  return results;
};

export const getJurisdictionWiseZones = (allLocations, userLocationPath = '') => {
  // return allLocations.children;

  if (userLocationPath === '/') {
    return allLocations?.children ?? [];
  }

  return (
    allLocations?.children?.filter(
      (ele) => ele.path.split('/')[1] === userLocationPath.split('/')[1],
    ) ?? []
  );
};

export const getLocationHierarchyByPath = (locationPath, locationList) => {
  let locationId = locationPath.split('/')[0];
  let result = {};

  for (let location of locationList) {
    if (+location.id === +locationId) {
      let data = { id: '', nameBangla: '', nameEnglish: '' };
      data.id = location.id;
      data.nameBangla = location.nameBangla;
      data.nameEnglish = location.nameEnglish;
      result.data = data;
      let separatorIndex = locationPath.indexOf('/');
      let childLocationPath = locationPath.substring(separatorIndex + 1);
      if (childLocationPath) {
        let child = getLocationHierarchyByPath(childLocationPath, location.children);
        result.child = child;
      }
    }
  }

  return result;
};

const prepareLocationObject = (locationHierarchy) => {
  return {
    id: locationHierarchy?.id ?? '',
    nameEnglish: locationHierarchy?.nameEnglish ?? '',
    nameBangla: locationHierarchy?.nameBangla ?? '',
    children: locationHierarchy?.children ?? [],
    path: locationHierarchy?.path ?? '',
  };
};

export const getHierarchicalLocationFromPath = (path, locationHierarchy, level = 1) => {
  if (level > 4) {
    return prepareLocationObject();
  }

  if (path === locationHierarchy.path) {
    return prepareLocationObject(locationHierarchy);
  }

  const locationId = path.split('/')[level];
  let childIndex = 0;

  for (let loc in locationHierarchy.children) {
    if (+locationHierarchy.children[loc].id === +locationId) {
      childIndex = loc;
    }
  }

  return getHierarchicalLocationFromPath(path, locationHierarchy.children[childIndex], level + 1);
};

export const getDefaultFormatDateFromTimestamp = (timeStamp) => {
  const date = new Date(timeStamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const digitConversion = (englishNumber, language) => {
  const { appLanguage } = store.getState().general;
  if (englishNumber === undefined || englishNumber == null) {
    englishNumber = 0;
  }

  if (language === appLanguages.English || (!language && appLanguage === appLanguages.English)) {
    return englishNumber;
  }
  const banglaDigitsMap = {
    0: '০',
    1: '১',
    2: '২',
    3: '৩',
    4: '৪',
    5: '৫',
    6: '৬',
    7: '৭',
    8: '৮',
    9: '৯',
  };
  const result = englishNumber
    .toString()
    .replace(/\d/g, (digit) => banglaDigitsMap[digit] || digit);

  return result;
};

export const isBanglaText = (text) => {
  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    if (
      (char >= '0' && char <= '9') ||
      (char >= 'a' && char <= 'z') ||
      (char >= 'A' && char <= 'Z')
    ) {
      return false;
    }
  }
  return true;
};

export const isEnglishText = (text) => {
  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    if (char.charCodeAt(0) > 256) {
      return false;
    }
  }
  return true;
};

export const dateTimeFormatter = (date, format, language = appLanguages.English) => {
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = String(date.getDate()).padStart(2, '0');

  const monthName = monthConversion(date, language);

  let formattedDate = '';

  switch (format) {
    case dateFormat.YYYYMMDD:
      formattedDate = `${digitConversion(year, language)}-${digitConversion(
        month,
        language,
      )}-${digitConversion(day, language)}`;
      break;
    case dateFormat.DDMMYYYY:
      formattedDate = `${digitConversion(day, language)}-${digitConversion(
        String(month + 1).padStart(2, '0'),
        language,
      )}-${digitConversion(year, language)}`;
      break;

    case dateFormat.DDMMMYYYY:
      formattedDate = `${digitConversion(day, language)} ${monthName}, ${digitConversion(
        year,
        language,
      )}`;
      break;

    case dateFormat.MMM:
      formattedDate = `${monthName}`;
      break;

    case dateFormat.MMMYY:
      formattedDate = `${monthName} ${digitConversion(+year % 100, language)}`;
      break;

    case dateFormat.MMMYYYY:
      formattedDate = `${monthName} ${digitConversion(year, language)}`;
      break;
    default:
      formattedDate = `${digitConversion(year, language)}-${digitConversion(
        month,
        language,
      )}-${digitConversion(day, language)}`;
  }

  return formattedDate;
};

export const weekDayConversion = (date, language) => {
  const weekEn = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const weekBn = ['রবিবার', 'সোমবার', 'মঙ্গলবার', 'বুধবার', 'বৃহস্পতিবার', 'শুক্রবার', 'শনিবার'];

  const weekDayIndex = date.getDay();
  return language === appLanguages.English ? weekEn[weekDayIndex] : weekBn[weekDayIndex];
};

export const monthConversion = (date, language) => {
  const monthEn = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthBn = [
    'জানুয়ারি',
    'ফেব্রুয়ারী',
    'মার্চ',
    'এপ্রিল',
    'মে',
    'জুন',
    'জুলাই',
    'আগস্ট',
    'সেপ্টেম্বর',
    'অক্টোবর',
    'নভেম্বর',
    'ডিসেম্বর',
  ];

  const monthIndex = date.getMonth();
  return language === appLanguages.English ? monthEn[monthIndex] : monthBn[monthIndex];
};

export const en2BnConversion = (valueBangla, valueEnglish, language, defaultValue = '-') => {
  if (!language) {
  }

  const { appLanguage } = store.getState().general;
  let targetValue = appLanguage === appLanguages.English ? valueEnglish : valueBangla;
  if (!targetValue) {
    targetValue = appLanguage === appLanguages.English ? valueBangla : valueEnglish;
  }
  return targetValue ? targetValue : defaultValue;
};

export const groupByAllMonth = (data = [], year = new Date().getFullYear()) => {
  if (data.length) {
    year = new Date(data[0].visitDate).getFullYear();
  }
  const englishMonthNames = Array.from({ length: 12 }, (_, monthIndex) => {
    return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
      new Date(year, monthIndex, 1),
    );
  });

  const bengaliMonthNames = Array.from({ length: 12 }, (_, monthIndex) => {
    return new Intl.DateTimeFormat('bn-BD', { month: 'long' }).format(
      new Date(year, monthIndex, 1),
    );
  });

  const groupedData = englishMonthNames.map((englishMonthName, monthIndex) => {
    const nameEn = englishMonthName;
    const nameBn = bengaliMonthNames[monthIndex];
    const firstDayOfMonth = new Date(year, monthIndex, 1);

    return { nameEn, nameBn, date: firstDayOfMonth, data: [] };
  });

  data.forEach((item) => {
    const date = new Date(item.visitDate);
    const monthIndex = date.getMonth();

    groupedData[monthIndex].data.push(item);
  });

  return groupedData;
};

export const preparedListForCalendar = (events = [], holidays, appLanguage) => {
  const preparedEvent = events.map((ele) => ({
    title:
      appLanguage === appLanguages.Bengali ? ele.institute.nameBangla : ele.institute.nameEnglish,
    start: ele.visitDate,
    end: ele.visitDate,
    display: 'list-item',
    classNames: ['fc-regular-event'],
  }));

  const preparedHolidays = holidays.map((ele) => ({
    title: appLanguage === appLanguages.Bengali ? ele.nameBangla : ele.nameEnglish,
    start: ele.startDate,
    end: ele.endDate,
    display: 'none',
    classNames: ['fc-holiday-event'],
  }));

  return [...preparedEvent, ...preparedHolidays];
};

export const getDay = (date) => {
  const dateTime = new Date(date);
  return dateTime.getDate();
};

export const getMonth = (date) => {
  const dateTime = new Date(date);
  return dateTime.getMonth();
};

export const getYear = (date) => {
  const dateTime = new Date(date);
  return dateTime.getFullYear();
};

export const getSessionList = (
  lng = appLanguages.English,
  from = new Date().getFullYear() - 1,
  to = new Date().getFullYear(),
) => {
  let results = [];
  for (let i = +from; i <= +to; i++) {
    results.push({
      value: i,
      label: digitConversion(i, lng),
    });
  }
  return results;
};

export const getPlanSubmissionStatusItems = (appLanguage) => {
  return [
    {
      label: en2BnConversion('পরিকল্পনা জমা দিয়েছেন', 'Plan Submitted', appLanguage),
      value: 'SUBMITTED',
    },
    {
      label: en2BnConversion('পরিকল্পনা জমা দেননি', 'Plan not submitted', appLanguage),
      value: 'DRAFT',
    },
    {
      label: en2BnConversion('পরিকল্পনা বাতিল করা হয়েছে', 'Plan rejected', appLanguage),
      value: 'REJECTED',
    },
  ];
};

export const getISASICategorytems = () => {
  return [
    {
      label: 'A',
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
    {
      label: 'D',
      value: 'D',
    },
    {
      label: 'E',
      value: 'E',
    },
    {
      label: 'U',
      value: 'U',
    },
  ];
};

export const getInstituteType = (appLanguage) => {
  return [
    {
      label: en2BnConversion('স্কুল', 'School', appLanguage),
      value: 'SCHOOL',
    },
    {
      label: en2BnConversion('কলেজ', 'College', appLanguage),
      value: 'COLLEGE',
    },
    {
      label: en2BnConversion('স্কুল এন্ড কলেজ', 'School And College', appLanguage),
      value: 'SCHOOL_AND_COLLEGE',
    },
    {
      label: en2BnConversion('মাদ্রাসা', 'Madrasa', appLanguage),
      value: 'MADRASA',
    },
    {
      label: en2BnConversion('অন্যান্য', 'Others', appLanguage),
      value: 'OTHERS',
    },
  ];
};

export const getInstituteMgmtType = (appLanguage) => {
  return [
    {
      label: en2BnConversion('সরকারি', 'Government', appLanguage),
      value: 'GOVERNMENT',
    },
    {
      label: en2BnConversion('বেসরকারী', 'Non-Government', appLanguage),
      value: 'NON_GOVERNMENT',
    },
    {
      label: en2BnConversion('স্থানীয় সরকারি', 'Local Government', appLanguage),
      value: 'LOCAL_GOVERNMENT',
    },
    {
      label: en2BnConversion('স্বায়ত্তশাসিত', 'Autonomous', appLanguage),
      value: 'AUTONOMOUS',
    },
    {
      label: en2BnConversion('অন্যান্য', 'Others', appLanguage),
      value: 'OTHERS',
    },
    {
      label: en2BnConversion('জানা নেই', 'Unknown', appLanguage),
      value: 'UNKNOWN',
    },
  ];
};

export const getToasterVariant = (status) => {
  if (status === STATUS.SUCCESS) {
    return colorVariant.success;
  }
  if (status === STATUS.ERROR) {
    return colorVariant.danger;
  }
};

export const getToasterHeader = (status) => {
  if (status === STATUS.SUCCESS) {
    return 'success';
  }
  if (status === STATUS.ERROR) {
    return 'fail';
  }
};

export const getToasterMessage = (status, successMsg, errMsg) => {
  if (status === STATUS.SUCCESS) {
    return successMsg;
  }
  if (status === STATUS.ERROR) {
    return errMsg;
  }
};

export const getNoticeAttachmentUrl = (uuid, fileName) => {
  let preparedUrl = UPLOAD_PATH + process.env.REACT_APP_NOTICE_ATTACHEMENT_PATH;
  return preparedUrl + '/' + uuid + '_' + fileName;
};

export const getSurveyImageUrl = (fileName) => {
  return UPLOAD_PATH + fileName;
};

export const MONITORING_TYPE_LOCATION_BASED = {
  zone: 'zone',
  district: 'district',
  upazila: 'upazila',
  national: 'national',
};

export const getLocationParams = (location) => {
  let params = '';
  if (location.zoneId) {
    params = params + 'zoneId=' + location.zoneId;
  }
  if (location.districtId) {
    params = params + '&' + 'districtId=' + location.districtId;
  }
  if (location.upazilaId) {
    params = params + '&' + 'upazilaId=' + location.upazilaId;
  }
  return params;
};

export const getAuditActionType = (appLanguage) => {
  return [
    {
      label: en2BnConversion('পরিকল্পনা সেশন যুক্ত করণ', 'Plan Session Addition', appLanguage),
      value: 'PLAN_SESSION_ADDED',
    },
    {
      label: en2BnConversion('পরিকল্পনা সেশন আপডেট', 'Plan Session Updation', appLanguage),
      value: 'PLAN_SESSION_UPDATED',
    },
    {
      label: en2BnConversion('পরিকল্পনা সংরক্ষণ', 'Plan Submission', appLanguage),
      value: 'PLAN_SUBMITTED',
    },
    {
      label: en2BnConversion(
        'প্রতিষ্ঠান মনিটরিং পরিকল্পনা যুক্ত করণ',
        'Institute Visit Plan Addition',
        appLanguage,
      ),
      value: 'INSTITUTE_VISIT_PLAN_ADDED',
    },
    {
      label: en2BnConversion(
        'প্রতিষ্ঠান মনিটরিং পরিকল্পনা মোছা',
        'Institute Visit Plan Deletion',
        appLanguage,
      ),
      value: 'INSTITUTE_VISIT_PLAN_DELETED',
    },
    {
      label: en2BnConversion('পরিকল্পনা প্রত্যাখ্যান', 'Plan Rejection', appLanguage),
      value: 'PLAN_REJECTED',
    },
    {
      label: en2BnConversion('ছুটি যুক্ত করণ', 'Holiday Addition', appLanguage),
      value: 'HOLIDAY_ADDED',
    },
    {
      label: en2BnConversion('ছুটি মোছা', 'Holiday Deletion', appLanguage),
      value: 'HOLIDAY_DELETED',
    },
    {
      label: en2BnConversion(
        'শ্রেনীকক্ষ মনিটরিং যুক্ত করণ',
        'Classroom Monitoring Addition',
        appLanguage,
      ),
      value: 'CLASSROOM_MONITORING_ADDED',
    },
    {
      label: en2BnConversion(
        'সাধারন মনিটরিং যুক্ত করণ',
        'General Monitoring Addition',
        appLanguage,
      ),
      value: 'GENERAL_MONITORING_ADDED',
    },
  ];
};

export const USER_TYPE = {
  officer: 'OFFICER',
  teacher: 'TEACHER',
  inspector: 'INSPECTOR',
  guest: 'GUEST',
  all: 'all',
};

export const getLocationPath = (location) => {
  let path = '/';
  if (location.zoneId) {
    path = path + location.zoneId + '/';
  }
  if (location.districtId) {
    path = path + location.districtId + '/';
  }
  if (location.upazilaId) {
    path = path + location.upazilaId + '/';
  }
  return path;
};

export const getLocationTypeFromPath = (path = '') => {
  const pathArray = path.split('/');
  switch (pathArray.length) {
    case 3:
      return LOCATION_TYPE.zone;
    case 4:
      return LOCATION_TYPE.district;
    case 5:
      return LOCATION_TYPE.upazila;
    default:
      return LOCATION_TYPE.national;
  }
};

export const getLocationTypeFromLocation = (location) => {
  if (location?.upazila?.id) {
    return MONITORING_TYPE_LOCATION_BASED.upazila;
  }
  if (location?.district?.id) {
    return MONITORING_TYPE_LOCATION_BASED.district;
  }
  if (location?.zone?.id) {
    return MONITORING_TYPE_LOCATION_BASED.zone;
  }
  return MONITORING_TYPE_LOCATION_BASED.national;
};

export const reportDownloadConfig = {
  responseType: 'blob',
};

export const prepareDownloadFile = (data, fileName, type = '') => {
  if (type === REPORT_TYPE.EXCEL) {
    type = 'XLSX';
  }
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${type.toLowerCase()}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getClassOrdinalNumber = (klass, appLanguage) => {
  const map = {
    6: en2BnConversion('ষষ্ঠ', 'Six', appLanguage),
    7: en2BnConversion('সপ্তম', 'Seven', appLanguage),
    8: en2BnConversion('অষ্টম', 'Eight', appLanguage),
    9: en2BnConversion('নবম', 'Nine', appLanguage),
    10: en2BnConversion('দশম', 'Ten', appLanguage),
    11: en2BnConversion('একাদশ', 'Eleven', appLanguage),
    12: en2BnConversion('দ্বাদশ', 'Twelve', appLanguage),
  };
  return map[klass] || en2BnConversion('অজানা', 'UNKNOWN', appLanguage);
};

export const getReportDownloadOptions = () => {
  const { appLanguage } = store.getState().general;
  return [
    { id: 1, type: REPORT_TYPE.PDF, label: en2BnConversion('পিডিএফ', 'PDF', appLanguage) },
    { id: 2, type: REPORT_TYPE.EXCEL, label: en2BnConversion('এক্সসেল', 'EXCEL', appLanguage) },
    { id: 3, type: REPORT_TYPE.CSV, label: en2BnConversion('সিএসভি', 'CSV', appLanguage) },
  ];
};

export const getHomeRoutes = () => {
  const { isLoggedIn } = store.getState().auth;
  if (isLoggedIn) return appRoutes.dashboard;
  return appRoutes.root;
};

export const showZoneTab = (level) => {
  if (level === LOCATION_TYPE.zone || level === LOCATION_TYPE.national) {
    return true;
  }

  return false;
};

export const getFormattedPublishedDate = (publishedDate, appLanguage) => {
  const date = new Date(publishedDate);
  const formattedDate = dateTimeFormatter(date, dateFormat.DDMMMYYYY, appLanguage);
  const dayInWeek = weekDayConversion(date, appLanguage);
  return `${formattedDate} - ${dayInWeek}`;
};

export const getFormattedTimeline = (publishedAt, expiresAt, appLanguage) => {
  const publishedDate = dateTimeFormatter(new Date(publishedAt), dateFormat.DDMMMYYYY, appLanguage);
  const expiresDate = dateTimeFormatter(new Date(expiresAt), dateFormat.DDMMMYYYY, appLanguage);

  return `${publishedDate} - ${expiresDate}`;
};

export const isInvalidDateRange = (startDate, endDate) => {
  return new Date(startDate) > new Date(endDate);
};
