import { getLocationPath } from '../../utils/helperFunction';

export const instituteApiRoutes = {
  getByPath: (zoneId, districtId, upazilaId) =>
    `/api/educational-institute/get-by-path?path=${encodeURIComponent(
      getLocationPath({ zoneId, districtId, upazilaId }),
    )}`,
  getNonCompliantInstitutes: (zone, district, upazila, category, year) =>
    `/api/educational-institute/non-compliant-institutes?zone=${zone}&district=${district}&upazila=${upazila}&isasCategory=${category}&year=${year}`,
  allInstitutes: (
    pageNumber,
    pageSize,
    zone,
    district,
    upazila,
    category,
    mgmtType,
    type,
    searchValue,
  ) =>
    `/api/educational-institute/institutes?page=${pageNumber}&size=${pageSize}&zone=${zone}&district=${district}&upazila=${upazila}&isasCategory=${category}&mgmtType=${mgmtType}&type=${type}&searchParam=${searchValue}`,
  getInstituteDetailsByEiin: (eiin) => `/api/educational-institute/institutes/${eiin}`,
};
