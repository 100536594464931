import React from 'react';
import DatePicker, { DatePickerProps } from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { calendar_disabled, calendar_icon } from '../../assets';
import { en2BnConversion, getDefaultFormatDateFromTimestamp } from '../../utils/helperFunction';

interface AppDatePickerProps {
  value: Date | null;
  className?: string;
  onChange: (date: string) => void;
  locale?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const AppDatePicker: React.FC<AppDatePickerProps> = ({
  value,
  className = '',
  onChange,
  locale = 'bn',
  disabled = false,
  minDate,
  maxDate,
  ...rest
}) => {
  const getDayPlaceholder = () => en2BnConversion('দিন', 'dd', locale);
  const getMonthPlaceholder = () => en2BnConversion('মাস', 'mm', locale);
  const getYearPlaceholder = () => en2BnConversion('বছর', 'yyyy', locale);

  const handleChange = (date: Date | [Date | null, Date | null] | null) => {
    if (date instanceof Date) {
      onChange(getDefaultFormatDateFromTimestamp(date));
    } else if (Array.isArray(date) && date[0] instanceof Date) {
      onChange(getDefaultFormatDateFromTimestamp(date[0]));
    } else {
      onChange('');
    }
  };

  return (
    <DatePicker
      calendarIcon={
        <img src={disabled ? calendar_disabled : calendar_icon} height={24} alt='Calendar Icon' />
      }
      className={`react-date-picker_input ${className}`}
      value={value}
      locale={locale}
      clearIcon={null}
      format='dd-MM-y'
      onChange={handleChange}
      dayPlaceholder={getDayPlaceholder()}
      monthPlaceholder={getMonthPlaceholder()}
      yearPlaceholder={getYearPlaceholder()}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      {...(rest as DatePickerProps)}
    />
  );
};

export default AppDatePicker;
