import React from 'react';
import { useNavigate } from 'react-router-dom';
import { not_found } from '../../assets/index';
import Button from './Button';
import { appRoutes } from '../../constants/appRoutes';

const NoMatch = () => {
  const navigate = useNavigate();

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-6 align-self-center'>
          <img src={not_found} className='w-100' />
        </div>
        <div className='col-md-6 align-self-center'>
          <h1 className='fw-bold'>404</h1>
          <h2>Page Not Found</h2>
          <p>
            The page you are looking for does not exist. How you got here is a mystery. But you can
            click the button below to go back to the homepage.
          </p>
          <div className='d-flex gap-3'>
            <Button
              className='btn btn-primary fw-bold'
              onClick={() => {
                navigate(-1);
              }}
            >
              BACK
            </Button>
            <Button
              className='btn btn-success fw-bold'
              onClick={() => {
                navigate(appRoutes.root);
              }}
            >
              HOME
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
